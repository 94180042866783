import { createReducer } from 'deox';
import produce from 'immer';
import { setBodyOverflow } from 'src/helpers/utils';
import {
  openSideBarMenu,
  resetSectionLoading,
  setCloseUserSettings,
  setCookies,
  setCustomI18N,
  setDeepLink,
  setGameBoxWidgetClose,
  setGameBoxWidgetOpen,
  setGeneraLoading,
  setSectionLoading,
  setSelectedCategory,
  setSelectedFooterMenuItem,
  setSelectedSection,
  setShowUserSettings,
  setTagsData,
  setToggleBonusWallet,
  setToggleVipPoits,
  setTournamentIsOpen,
  toggleBalanceVisibility,
  toggleSideBarMenu,
} from './actions';
import { appState } from './types';

const initialState: appState = {
  sideBarMenuIsOpen: false,
  selectedSection: '',
  selectedSubSection: '',
  depositModalIsOpen: false,
  selectedCategory: localStorage.getItem('selectedCategory') || '',
  showUserSetting: false,
  homePageBanners: [],
  selectedFooterMenu: localStorage.getItem('selectedCategory') ?? null,
  cookiesAccepted: localStorage.getItem('cookiesAccepted') ?? null,
  customI18n: null,
  showMainBalance: true,
  isGameBoxOpen: false,
  tournamentIsOpen: false,
  showVipPoints: false,
  showBonusWallet: false,
  deepLink: '',
  sectionLoading: [],
  generalLoading: [],
  tagsData: null,
};

export const appReducer = createReducer(initialState, (handle) => [
  handle(setSelectedCategory, (state, { payload }: any) =>
    produce(state, (draft) => {
      if (draft.selectedCategory === 'casinoTournament' && payload !== 'casinoTournament') {
        const casinoTournamentIframe: any = document.querySelector('iframe#tournament');

        if (casinoTournamentIframe) {
          casinoTournamentIframe?.contentWindow.postMessage({ type: 'resetState' }, '*');
        }
      }

      draft.selectedCategory = payload;
      localStorage.setItem('selectedCategory', payload);
    })
  ),
  handle(setSelectedSection, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.selectedSection = payload.section;
      draft.selectedSubSection = payload.subSection;
    })
  ),

  handle(setCookies, (state, { payload }: any) =>
    produce(state, (draft) => {
      localStorage.setItem('cookiesAccepted', payload);
      draft.cookiesAccepted = payload;
    })
  ),

  handle(setShowUserSettings, (state, {}: any) =>
    produce(state, (draft) => {
      draft.showUserSetting = !state.showUserSetting;
    })
  ),
  handle(setSelectedFooterMenuItem, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.selectedFooterMenu = payload;
    })
  ),
  handle(setCustomI18N, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.customI18n = payload;
    })
  ),
  handle(setCloseUserSettings, (state) =>
    produce(state, (draft) => {
      draft.showUserSetting = false;
    })
  ),
  handle(toggleBalanceVisibility, (state) =>
    produce(state, (draft) => {
      draft.showMainBalance = !state.showMainBalance;
    })
  ),
  handle(toggleSideBarMenu, (state) =>
    produce(state, (draft) => {
      draft.sideBarMenuIsOpen = !state.sideBarMenuIsOpen;
    })
  ),
  handle(openSideBarMenu, (state) =>
    produce(state, (draft) => {
      draft.sideBarMenuIsOpen = true;
    })
  ),
  handle(setGameBoxWidgetOpen, (state) =>
    produce(state, (draft) => {
      setBodyOverflow('unset');
      draft.isGameBoxOpen = true;
    })
  ),
  handle(setGameBoxWidgetClose, (state) =>
    produce(state, (draft) => {
      setBodyOverflow('set');
      draft.isGameBoxOpen = false;
    })
  ),
  handle(setTournamentIsOpen, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.tournamentIsOpen = payload;
    })
  ),
  handle(setToggleVipPoits, (state) =>
    produce(state, (draft) => {
      draft.showVipPoints = !draft.showVipPoints;
    })
  ),
  handle(setToggleBonusWallet, (state) =>
    produce(state, (draft) => {
      draft.showBonusWallet = !draft.showBonusWallet;
    })
  ),
  handle(setDeepLink, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.deepLink = payload;
    })
  ),
  handle(setSectionLoading, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.sectionLoading = [...state.sectionLoading, payload];
    })
  ),
  handle(resetSectionLoading, (state) =>
    produce(state, (draft) => {
      draft.sectionLoading = [];
    })
  ),
  handle(setGeneraLoading, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.generalLoading = [...state.generalLoading, payload];
    })
  ),
  handle(setTagsData, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.tagsData = payload;
    })
  ),
]);
