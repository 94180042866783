import cn from 'classnames';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { Input } from 'src/components/common/Input';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { setBodyOverflow } from 'src/helpers/utils';
import { useOutsideClick } from 'src/hooks/use_outside_click';
import '../styles.scss';
import ScrollableViewCard from './ScrollableViewCard';

type ProviderScrollableViewProps = {
  providers: string[];
  selectedProvider: string[];
  onProviderChange: any;
  openProvidersList: boolean;
  togglePovidersFilterBtn: any;
  selectedCategory: string;
  setOpenProvidersList: (open: boolean) => void;
};
const ProviderScrollableView: FC<ProviderScrollableViewProps> = (props) => {
  const {
    providers,
    selectedProvider,
    onProviderChange,
    togglePovidersFilterBtn,
    openProvidersList,
    selectedCategory,
    setOpenProvidersList,
  } = props;

  const { t }: Translation = useTranslation();

  const tagsContainer = useRef<any>();
  const providerRef = useRef<any>();
  const wrapperRef = useRef<any>();

  const [search, setSearch] = useState<string>('');
  const [localProviders, setLocalProviders] = useState<string[]>([]);
  const [providersAdditionalStyles, setProvidersAdditionalStyles] = useState<string>('');
  const [visibleProviders, setVisibleProviders] = useState<string[]>([]);
  const [visibleSelectedProvidersCount, setVisibleSelectedProvidersCount] = useState<number>(0);

  const onSearchValueChange = (ev: ChangeEvent<HTMLInputElement>): void => {
    setSearch(ev.target.value);

    if (ev.target.value.length >= 3) {
      const result = providers?.filter((f) => f.toLowerCase().includes(ev.target.value.toLocaleLowerCase()));
      setLocalProviders(result);
    } else {
      setLocalProviders(providers);
    }
  };

  useEffect(() => {
    setVisibleProviders(providers?.slice(0, Math.floor(tagsContainer?.current?.clientWidth / 101)));
  }, [tagsContainer?.current?.clientWidth, selectedCategory, providers]);
  useEffect(() => {
    providers && setLocalProviders(providers);
  }, [providers, openProvidersList]);

  useEffect(() => {
    if (!openProvidersList) {
      setSearch('');
    }

    isMobileOnly && setBodyOverflow(openProvidersList ? 'unset' : 'set');
  }, [openProvidersList]);

  useEffect(() => {
    setVisibleSelectedProvidersCount(Math.floor(tagsContainer?.current?.clientWidth / 101));
  }, []);

  useEffect(() => {
    if (!isMobileOnly) {
      if (openProvidersList) {
        wrapperRef.current.scrollIntoView({ block: 'center' });
        setBodyOverflow('unset');
        setProvidersAdditionalStyles('providers_accordion_wrapper_opened');
      } else {
        setBodyOverflow('set');
        setProvidersAdditionalStyles('');
      }
    }
  }, [openProvidersList, isMobileOnly]);

  useOutsideClick(providerRef, () => setOpenProvidersList(false), null, [
    'show_more_btn',
    'button__content',
    'button__content__typography',
  ]);

  return (
    <>
      <div className="providers_accordion_wrapper" ref={wrapperRef}>
        <Typography className="title" variant={'h6'}>
          {t('all_providers')}
        </Typography>
        <div className={cn('providers_accordion_container', providersAdditionalStyles)}>
          {!openProvidersList && (
            <>
              {selectedProvider.length > 0 && !selectedProvider.includes('all_providers') ? (
                <>
                  <ScrollableViewCard provider={'all_providers'} onProviderChange={onProviderChange} />
                  {selectedProvider.slice(0, visibleSelectedProvidersCount).map((item) => (
                    <ScrollableViewCard key={item} provider={item} onProviderChange={onProviderChange} />
                  ))}
                </>
              ) : (
                <ScrollableViewCard provider={'all_providers'} onProviderChange={onProviderChange} />
              )}
            </>
          )}

          {!openProvidersList && (
            <div className="providers_wrapper" ref={tagsContainer}>
              <div className="pd">
                {providers
                  ?.filter((f) => f !== 'all_providers' && !selectedProvider.includes(f))
                  .map((provider: string) => (
                    <ScrollableViewCard key={provider} provider={provider} onProviderChange={onProviderChange} />
                  ))}
              </div>
            </div>
          )}

          {providers.length > visibleProviders.length && (
            <div className="provider_wrapper" ref={providerRef}>
              <Button
                id="provider_collapse_btn"
                fontWeight="600"
                className={cn('provider_collapse tab-image-btn', { ['active']: openProvidersList })}
                onClick={togglePovidersFilterBtn}
                icon={
                  openProvidersList
                    ? '/images/icons/svg/filled_arrow_up.svg'
                    : '/images/icons/svg/filled_arrow_down.svg'
                }
                iconPosition="right"
              >
                {selectedProvider && !selectedProvider.includes('all_providers')
                  ? t('selected') + ' ' + localStorage.getItem('selectedProvider')?.split(',').length
                  : t('all_providers')}
              </Button>
              {openProvidersList && (
                <div className="providers_container">
                  <div>
                    <div className="icon_wrapper close_icon" onClick={() => setOpenProvidersList(false)}>
                      <SvgIcon src="/images/icons/svg/close.svg" className="icon" />
                    </div>
                    <div className="provider_search_container">
                      <Input
                        id="modal_provider_search_inp"
                        onChange={onSearchValueChange}
                        value={search}
                        name={'search_provider_input'}
                        placeholder={t('search_providers')}
                        isTransparent
                      />
                      <div className="icon_wrapper">
                        <SvgIcon src="/images/icons/svg/search.svg" className="icon" />
                      </div>
                    </div>
                  </div>
                  <div className="cards">
                    {localProviders?.map((provider: string) => (
                      <ScrollableViewCard key={provider} provider={provider} onProviderChange={onProviderChange} />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {openProvidersList && !isMobileOnly && <div className="backdrop_provider zIndex_12"></div>}
    </>
  );
};
export default ProviderScrollableView;
