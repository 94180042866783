import cn from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'src/helpers/utils';
import { IActiveJackpotsType } from 'src/types/res-dto/raffleDraw';
import './styles.scss';

type IProps = {
  classnames?: string;
  data: IActiveJackpotsType;
};

const RaffleDrawRules: FC<IProps> = ({ classnames, data }) => {
  const { t } = useTranslation();

  return (
    <div className={cn('raffle_draw_rules_container', classnames)}>
      <h3 className="raffle_draw_rules_title">{t('rules')}</h3>
      <div className="raffle_draw_rules_content">
        <img
          src={`/images/raffleDraw/raffle_draw_tickets.png`}
          alt="raffle_draw_tickets"
          className="raffle_draw_tickets_img"
        />
        <div className="raffle_draw_rule_text">
          {data.name === 'Raffle Draw Gamedaddy' && (
            <p>
              Every wager: <span className="prize_value">{formatCurrency(20, data.currency)} + 1 Ticket</span>
            </p>
          )}
          {data.name === 'Newbie Deposit Raffle' && (
            <p>
              Register and deposit: <span className="prize_value">+ 1 Ticket</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RaffleDrawRules;
