import cn from 'classnames';
import { FC, useRef } from 'react';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import './styles.scss';

interface IAccordionDataType {
  title: string;
  description: string;
}

type IProps = {
  data: IAccordionDataType;
  index: number;
  accordionToggleHandler: (idx: number) => void;
  isOpen: boolean;
};

const AccordionItem: FC<IProps> = ({ data, index, accordionToggleHandler, isOpen }): any => {
  const contentHeight: any = useRef();

  return (
    <div className="accordion_item" onClick={() => accordionToggleHandler(index)}>
      <div className="accordion_item__header">
        <div className={cn('header_title', { ['header_title__active']: isOpen })}>{data.title}</div>
        <button className={cn('toggle_button', { ['toggle_button__active']: isOpen })}>
          <SvgIcon src={icons.arrowRight} size="sm" color="primary" className="toggle_button_icon" />
        </button>
      </div>
      <div
        ref={contentHeight}
        className={cn('accordion_item__body', { ['accordion_item__body__active']: isOpen })}
        style={isOpen ? { height: contentHeight.current.scrollHeight } : { height: '0px' }}
      >
        <div className="body_description">{data.description}</div>
      </div>
    </div>
  );
};

export default AccordionItem;
