import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

type Props = {
  name?: string; // Add a `name` prop for the group
  onChange?: (e: any) => void;
  required?: boolean;
  data: string[];
  value: string;
  disabled?: boolean;
  descriptions?: string[];
};

const RadioInput: FC<Props> = ({ name, onChange, data, value, required, disabled, descriptions }) => {
  const { t } = useTranslation();

  return (
    <div className="radio_buttons_wrapper">
      {data?.map((item: string, index: number) => (
        <div key={`${name}-${item}`} className="radio-item">
          <label htmlFor={`${name}-${item}`} className={`radio-label ${disabled ? 'radio-disabled' : ''}`}>
            <input
              className="radio-input"
              type="radio"
              name={name || item} // Set the group name
              id={name ? `${name}-${item}` : item} // Ensure a unique id for accessibility
              value={item}
              required={required}
              onChange={(e) => !disabled && onChange?.(e)} // Prevent onChange if disabled
              checked={item === value}
              disabled={disabled}
            />
            <span className="custom-radio" />
            {t(item)}
          </label>
          {descriptions?.length ? <p className="radio-text">{descriptions[index]}</p> : ''}
        </div>
      ))}
    </div>
  );
};

export default RadioInput;
