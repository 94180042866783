import { useEffect, useState } from 'react';

type ScrollData = {
  onScroll: (node: any) => void;
  showStart: boolean;
  showEnd: boolean;
};

export const useScrollWithShadow = (node: any, dependencies: string[] = [], width?: number): ScrollData => {
  const [showStart, setShowStart] = useState<boolean>(false);
  const [showEnd, setShowEnd] = useState<boolean>(false);

  const onScroll = (node: any): void => {
    const { scrollLeft = 0 } = node || {};
    // const effectiveWidth = width || node?.scrollWidth || 0;

    setShowStart(scrollLeft > 0);
    setShowEnd(scrollLeft === 0);
  };

  const _dependencies = Array.isArray(dependencies) ? [node, width, ...dependencies] : [node, width, dependencies];

  useEffect(() => {
    if (node) {
      onScroll(node);
      node.addEventListener('scroll', () => onScroll(node));
    }

    // Clean up event listener on unmount
    return () => {
      if (node) node.removeEventListener('scroll', () => onScroll(node));
    };
  }, _dependencies);

  return { onScroll, showStart, showEnd };
};
