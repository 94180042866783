import cn from 'classnames';
import { FC, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import GameCard from 'src/components/common/GameCard';
import { TEMPLATE_2_SLIDER_GAMES_COUNT } from 'src/utils/constants';
import '../styles.scss';
import { LeftArrow, RightArrow } from './Arrows';

type Props = {
  activeGames: any;
  viewAllGamesOfTag?: any;
  tagName: string;
  showArrows?: boolean | undefined;
  showViewAllLink?: boolean;
};
const Template2: FC<Props> = ({ activeGames, viewAllGamesOfTag, tagName }) => {
  const { t }: Translation = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  const [left, setLeft] = useState<number>(TEMPLATE_2_SLIDER_GAMES_COUNT);

  //TO DO ADD 161 AS SKIN VAR
  const onPrev = (): void => {
    setLeft((prev) => prev - TEMPLATE_2_SLIDER_GAMES_COUNT);

    (ref as any).current.scrollTo({
      left: (ref as any)?.current?.scrollLeft - TEMPLATE_2_SLIDER_GAMES_COUNT * 161,
      behavior: 'smooth',
    });
  };

  const onNext = (): void => {
    setLeft((prev) => prev + TEMPLATE_2_SLIDER_GAMES_COUNT);

    (ref as any).current.scrollTo({
      left: (ref as any)?.current?.scrollLeft + TEMPLATE_2_SLIDER_GAMES_COUNT * 161,
      behavior: 'smooth',
    });
  };

  return (
    <div className={cn('game_container', 'template_2')}>
      <div className="game_container_header">
        <div className="game_container_header_title_with_arrows">
          <Button ariaLabelledby="arrow" type="button" onClick={viewAllGamesOfTag} className="tag_title">
            {t(tagName)}
          </Button>
        </div>

        <div className="game_btn_container">
          <Button type="button" onClick={viewAllGamesOfTag} className="viewAll success-outlined-btn">
            {t('viewAll')}
          </Button>
          {!isMobileOnly && activeGames?.length > TEMPLATE_2_SLIDER_GAMES_COUNT && (
            <div className="arrows">
              <LeftArrow
                disabled={left === TEMPLATE_2_SLIDER_GAMES_COUNT}
                placement="center"
                type="squaredDark"
                style="primary-filled-btn"
                slide={onPrev}
              />
              <RightArrow
                disabled={activeGames.length < left}
                placement="center"
                style="primary-filled-btn"
                type="squaredDark"
                slide={onNext}
              />
            </div>
          )}
        </div>
      </div>
      {isMobileOnly ? (
        <div className="mobile_game_carousel_container" style={{ height: 'auto' }}>
          {!!activeGames &&
            activeGames?.map((carouselItemArr: any, i: number) => {
              if (Array.isArray(carouselItemArr)) {
                return carouselItemArr.map((g: any) => {
                  return (
                    <GameCard
                      key={g.name + i}
                      data={g}
                      showDetails={false}
                      size={'sm'}
                      useRatio
                      template={'template_2'}
                      effects={{
                        hoverType: '',
                        src: '',
                        text: '',
                      }}
                    />
                  );
                });
              }
              return (
                <GameCard
                  key={carouselItemArr.name + i}
                  data={carouselItemArr}
                  showDetails={false}
                  size={'sm'}
                  useRatio
                  template={'template_2'}
                  effects={{
                    hoverType: '',
                    src: '',
                    text: '',
                  }}
                />
              );
            })}
        </div>
      ) : (
        <div className="game_carousel_container">
          <div className="inner_carousel">
            <div className="slider_template_2" ref={ref}>
              {activeGames?.map((carouselItemArr: any, i: number) => {
                return (
                  <GameCard
                    key={carouselItemArr.name + i}
                    data={carouselItemArr}
                    showDetails={false}
                    size={'md_s'}
                    effects={{
                      hoverType: 'fullWidthImage',
                      src: '',
                      text: '',
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Template2;
