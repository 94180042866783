import { APP_API_VERSION } from 'src/utils/constants';
import HttpClient from './http';

export const getPlayerLimits = (): Promise<any> => {
  return HttpClient.get(
    { path: `${APP_API_VERSION}/getPlayerLimits`, baseURL: process.env.REACT_APP_REMOTE as string },
    true
  );
};
export const setPlayerLimits = (payload: any): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/editPlayerLimits`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    true
  );
};
export const resetAlertLimit = (payload: { limitType: string; timePeriod: string }): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/resetAlertingPlayerLimit`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    true
  );
};
