import cn from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'src/helpers/utils';
import { IRaffleDrawPrizePoolsData } from 'src/types/res-dto/raffleDraw';
import './styles.scss';

type IProps = {
  classnames?: string;
  data: IRaffleDrawPrizePoolsData[];
};

const RaffleDrawPrizePools: FC<IProps> = ({ classnames, data }) => {
  const { t } = useTranslation();

  return (
    <div className={cn('raffle_draw_prize_pools_container', classnames)}>
      <h3 className="raffle_draw_prize_pools_title">{t('prizePool')}</h3>
      <div className="raffle_draw_prize_pools_table">
        <div className="raffle_draw_prize_pools_table__header">
          <div className="raffle_draw_prize_pools_table__header_column">place</div>
          <div className="raffle_draw_prize_pools_table__header_column">prize</div>
        </div>
        <div className="raffle_draw_prize_pools_table__body">
          {data.map((item: IRaffleDrawPrizePoolsData) => (
            <div key={item.place} className="raffle_draw_prize_pools_table__body_row">
              <div className="raffle_draw_prize_pools_table__body_column">
                <span>
                  {item.place} {t('place')}
                </span>
              </div>
              <div className="raffle_draw_prize_pools_table__body_column">
                <span>{formatCurrency(item.prize.amount, item.prize.currency)}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RaffleDrawPrizePools;
