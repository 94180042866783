import { APP_API } from 'src/utils/constants';
import HttpClient from './http';

const getActiveJackpots = (): Promise<any> => {
  return HttpClient.get({
    path: `${APP_API}/getActiveJackpots`,
    baseURL: process.env.REACT_APP_GAME_BOX as string,
  });
};

const getActiveJackpotsTickets = (): Promise<any> => {
  return HttpClient.get({
    path: `${APP_API}/getActiveJackpotsTickets`,
    baseURL: process.env.REACT_APP_GAME_BOX as string,
  });
};

export { getActiveJackpots, getActiveJackpotsTickets };
