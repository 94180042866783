import cn from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Accordion from 'src/components/common/Accordion';
import { formatCurrency } from 'src/helpers/utils';
import { getActiveJackpots, getActiveJackpotsTickets } from 'src/services/raffleDrawApi';
import {
  IActiveJackpotsTicketsType,
  IActiveJackpotsType,
  IRaffleDrawPrizePoolsData,
} from 'src/types/res-dto/raffleDraw';
import { RootState } from 'src/types/store-types';
import RaffleDrawInfo from './components/RaffleDrawInfo';
import RaffleDrawPrizePools from './components/RaffleDrawPrizePools';
import RaffleDrawRules from './components/RaffleDrawRules';
import RaffleDrawTickets from './components/RaffleDrawTickets';
import './styles.scss';

//! REMOVE
const DATA_1 = [
  {
    title: 'Lorem ipsum dolor sit amet?',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
      Praesent vehicula tempor lacus, non dignissim orci interdum eget. 
      Integer tincidunt tincidunt erat, non placerat lorem interdum a.`,
  },
  {
    title: 'Curabitur ac leo nunc?',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
      Maecenas non purus velit. Duis interdum tempor sapien id ornare. 
      Ut hendrerit, eros non ullamcorper feugiat, neque justo cursus eros, 
      vel malesuada lectus velit et orci.`,
  },
  {
    title: 'Vestibulum ante ipsum primis?',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
      Phasellus volutpat tincidunt tellus, sit amet pharetra neque malesuada ut. 
      Quisque aliquam fermentum facilisis. Fusce vel velit sed orci fermentum volutpat.`,
  },
  {
    title: 'Aenean eget metus id metus?',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
      Donec eget neque vel dui fermentum tincidunt. In semper, orci at fermentum bibendum, 
      odio ligula consequat nisl, et suscipit ex urna in odio.`,
  },
  {
    title: 'Suspendisse potenti?',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
      Cras a tempor justo, et viverra nisi. Nam volutpat lacus at diam auctor, 
      sed tempor mauris luctus. Pellentesque in risus auctor, egestas erat non, dignissim orci.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.`,
  },
];

//! REMOVE
const PRIZE_DATA_1: IRaffleDrawPrizePoolsData[] = [
  { place: 1, prize: { currency: 'USD', amount: 2500 } },
  { place: 2, prize: { currency: 'USD', amount: 1250 } },
  { place: 3, prize: { currency: 'USD', amount: 750 } },
  { place: 4, prize: { currency: 'USD', amount: 300 } },
  { place: 5, prize: { currency: 'USD', amount: 200 } },
];

//! REMOVE
const PRIZE_DATA_2: IRaffleDrawPrizePoolsData[] = [
  { place: 1, prize: { currency: 'USD', amount: 3500 } },
  { place: 2, prize: { currency: 'USD', amount: 3000 } },
  { place: 3, prize: { currency: 'USD', amount: 2000 } },
  { place: 4, prize: { currency: 'USD', amount: 1500 } },
  { place: 5, prize: { currency: 'USD', amount: 1000 } },
];

const RaffleDraw: FC = () => {
  // Selectors
  const { user } = useSelector((state: RootState) => state.user);

  // States
  const [jackpots, setJackpots] = useState<IActiveJackpotsType[] | null>(null);
  const [jackpotTickets, setJackpotTickets] = useState<IActiveJackpotsTicketsType[] | null>(null);
  const [data, setData] = useState<any>({});
  const [selectedTab, setSelectedTab] = useState<any>();

  // API Calls
  const getActiveJackpotsReq = async (): Promise<void> => {
    const res: any = await getActiveJackpots();
    if (res.success) {
      setJackpots(res.result.jackpots);
    } else {
      console.error(res);
    }
  };

  const getActiveJackpotsTicketsReq = async (): Promise<void> => {
    const res: any = await getActiveJackpotsTickets();
    if (res.success) {
      setJackpotTickets(res.result);
    } else {
      console.error(res);
    }
  };

  // Actions
  const onSelectTab = (tab: string): void => {
    setSelectedTab(tab);
  };

  // Effects
  useEffect(() => {
    if (user) {
      getActiveJackpotsReq();
      getActiveJackpotsTicketsReq();
    }
  }, [user]);

  // Effects
  useEffect(() => {
    if (jackpots && jackpotTickets) {
      const jackpotsData = jackpots.reduce((acc: any, cur: IActiveJackpotsType) => {
        const findedTickets = jackpotTickets.find((el: IActiveJackpotsTicketsType) => el.name === cur.name);
        acc[`${cur.id}`] = {
          jackpot: cur,
          tickets: findedTickets,
        };
        return acc;
      }, {});
      setData(jackpotsData);
      setSelectedTab(Object.keys(jackpotsData)[0]);
    }
  }, [jackpots, jackpotTickets]);

  return (
    <div
      className={cn('raffle_draw_content_wrapper', { ['with_tabs']: Object.keys(data).length > 1 })}
      id="raffleDrawContainer"
    >
      {data && selectedTab && (
        <>
          {Object.keys(data).length > 1 ? (
            <div className="raffle_draw_tabs_wrapper">
              <div className="raffle_draw_tabs">
                {Object.keys(data).map((dataKey: any) => (
                  <div
                    onClick={() => onSelectTab(dataKey)}
                    key={dataKey}
                    className={cn('tab_item', { ['selected']: selectedTab === dataKey })}
                  >
                    <span className="raffle_draw_name">{data[dataKey].jackpot.name}</span>
                    <span className="raffle_draw_prize">
                      {formatCurrency(data[dataKey].jackpot.total_prize, data[dataKey].jackpot.currency)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          <div className="raffle_draw_content">
            <RaffleDrawInfo data={data[selectedTab]?.jackpot} classnames="raffle_draw_banner" />
            <RaffleDrawRules data={data[selectedTab]?.jackpot} classnames="raffle_draw_rule" />
            <RaffleDrawPrizePools
              data={selectedTab == Object.keys(data)[0] ? PRIZE_DATA_1 : PRIZE_DATA_2}
              classnames="raffle_draw_prize_pools"
            />
            <RaffleDrawTickets data={data[selectedTab]?.tickets} classnames="raffle_draw_tickets" />
            <Accordion data={DATA_1} title={'FAQ'} />
          </div>
        </>
      )}
    </div>
  );
};

export default RaffleDraw;
