import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeErrorMessage } from 'src/store/dialog/actions';
import { setLogout } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

const ErrorMessage: FC = () => {
  const { t }: Translation = useTranslation();

  const _closeErrorMessage = useConnectedAction(closeErrorMessage);
  const [idIsCopied, setIdIsCopied] = useState<boolean>(false);
  const { showErrorPopup, error, errorParam, requestTrackId } = useSelector((state: RootState) => state.dialog);

  const removeDialog = (): void => {
    _closeErrorMessage();
  };

  const _logout = useConnectedAction(setLogout);

  useEffect(() => {
    if (showErrorPopup) {
      setBodyOverflow('unset');
    }
  }, [showErrorPopup]);

  const copyRequestTrackId = (): void => {
    const textArea = document.createElement('textarea');
    textArea.value = requestTrackId;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      setIdIsCopied(true);

      setTimeout(() => {
        setIdIsCopied(false);
      }, 1500);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body?.removeChild(textArea);
  };

  if (error && error !== 'undefined') {
    const translationParams = typeof errorParam === 'string' ? { param: errorParam } : errorParam;
    let errorText = error;
    if (errorParam?.includes('otp')) {
      errorText = 'invalid_otp';
    }
    const handleClose = (): void => {
      if (error.includes('token')) {
        _logout({ onSuccessCb: removeDialog });
      } else {
        removeDialog();
      }
    };
    return (
      <PopUpContainer
        isOpen={showErrorPopup}
        handleClose={handleClose}
        handleOK={handleClose}
        icon={<SvgIcon src={icons.errorIcon} />}
        type="small"
        hasOverlay
        id="error_message"
        zIndex={160}
      >
        <p className="error_message_title">
          {errorText === 'invalid_otp' ? t(errorText) : t(errorText, translationParams)}
        </p>
        {requestTrackId && (
          <>
            <span className="error_message_id">
              {t('id')}: {requestTrackId}
              <SvgIcon src="/images/copy.svg" onClick={copyRequestTrackId} />
            </span>
            <span className="copied">{idIsCopied && t('copied')}</span>
          </>
        )}
      </PopUpContainer>
    );
  }
  return <></>;
};

export default ErrorMessage;
