import AdvertisementList from 'src/components/base-components/AdvertisementList';
import AgeInfo from 'src/components/base-components/AgeInfo';
import Awards from 'src/components/base-components/Awards/Awards';
import BackToTop from 'src/components/base-components/BackToTop';
import Balance from 'src/components/base-components/Balance';
import BL_WINs from 'src/components/base-components/BL_WINs/BL_WINs';
import BottomNavigation from 'src/components/base-components/BottomNavigation';
import CerificateList from 'src/components/base-components/CerificateList';
import LiveChat from 'src/components/base-components/Chat/LiveChat';
import LiveChatButton from 'src/components/base-components/Chat/LiveChatButton';
import CookieSettingManager from 'src/components/base-components/CookieSettingManager';
import CopyRight from 'src/components/base-components/CopyRight';
import Deposit from 'src/components/base-components/Deposit';
import ExternalLinkButton from 'src/components/base-components/ExternalLinkButton';
import FeaturedGames from 'src/components/base-components/FeaturedGames';
import GambleAware from 'src/components/base-components/GambleAware';
import GameBoxToggle from 'src/components/base-components/GameBoxToggle';
import GameCare from 'src/components/base-components/GameCare';
import GameTags from 'src/components/base-components/GameTags';
import HelpCenterWidget from 'src/components/base-components/HelpCenterWidget';
import HomeWidgets from 'src/components/base-components/HomeWidgets/HomeWidgets';
import ImageWidget from 'src/components/base-components/ImageWidget';
import Licence from 'src/components/base-components/Licence';
import MailButton from 'src/components/base-components/MailButton';
import MobileApp from 'src/components/base-components/MobileApp';
import { Navigation } from 'src/components/base-components/Navigation';
import NewsLetter from 'src/components/base-components/NewsLetter';
import Mobile_Menu_Button from 'src/components/base-components/OnlyMobileComponents/Mobile_Menu_Button';
import OperatorBlock from 'src/components/base-components/OperatorBlock';
import PaymentList from 'src/components/base-components/PaymentList';
import Poker from 'src/components/base-components/Poker';
import ProviderBlock from 'src/components/base-components/ProviderBlock';
import ProviderFilter from 'src/components/base-components/ProviderFilter';
import ProviderList from 'src/components/base-components/ProviderList';
import RaffleDraw from 'src/components/base-components/RaffleDraw';
import ReferAFriendWidget from 'src/components/base-components/ReferAFriendWidget';
import RegulationIcons from 'src/components/base-components/RegulationIcons';
import ResponsibleGaming from 'src/components/base-components/ResponsibleGaming';
import Search from 'src/components/base-components/Search';
import SideBarNavigation from 'src/components/base-components/SideBarNavigation/SideBarNavigation';
import SideBarPromotion from 'src/components/base-components/SideBarPromotion/SideBarPromotion';
import Slider from 'src/components/base-components/Slider';
import HomePageGamesSlider from 'src/components/base-components/Slider/HomePageGamesSlider';
import HomePageGamesSliders from 'src/components/base-components/Slider/HomePageGamesSlider/HomePageGamesSliders';
import TagRow from 'src/components/base-components/TagRow/TagRow';
import TermsBriefInfo from 'src/components/base-components/TermsBriefInfo';
import TextWidget from 'src/components/base-components/TextWidget';
import TopCategories from 'src/components/base-components/TopCategories';
import UserSettings from 'src/components/base-components/UserSettings';
import Address_Input from 'src/components/common/Address_Input';
import BirthDate_Input from 'src/components/common/BirthDate_Input';
import City_Input from 'src/components/common/City_Input';
import Country_Select from 'src/components/common/Country_Select';
import CPF_Input from 'src/components/common/CPF_input';
import Currency_Select from 'src/components/common/Currency_Select.tsx';
import DNI_Input from 'src/components/common/DNI_Input';
import Email_Input from 'src/components/common/Email_Input';
import FirstName_Input from 'src/components/common/FirstName_Input';
import Gender_Select from 'src/components/common/Gender_Select';
import ID_Main_Issue_Input from 'src/components/common/ID_Main_Issue_Input';
import LastName_Input from 'src/components/common/LastName_Input';
import NationalId_input from 'src/components/common/NationalId_input';
import ParentId from 'src/components/common/ParentId_Input';
import Password_Input from 'src/components/common/Password_Input';
import Phone_Number_Input from 'src/components/common/Phone_Number_Input';
import Repeat_Password_Input from 'src/components/common/Repeat_Password_Input';
import Username_Input from 'src/components/common/Username_Input';
import ZipCode_Input from 'src/components/common/ZipCode_Input';
import Auth from '../../components/base-components/Auth';
import BlogWidget from '../../components/base-components/BlogWidget';
import FooterLogos from '../../components/base-components/FooterLogos';
import ImageWidgetAwards from '../../components/base-components/ImageWidgetAwards';
import ImageWidgetLiga from '../../components/base-components/ImageWidgetLiga/ImageWidgetLiga';
import Language from '../../components/base-components/Language';
import { Logo } from '../../components/base-components/Logo';
import Promotion from '../../components/base-components/Promotion';
import SideBarToggle from '../../components/base-components/SideBarToggle';
import SocialNetworks from '../../components/base-components/SocialNetworks';
import TLTJPWidget from '../../components/base-components/TLTJPWidget';
import TLTRaces from '../../components/base-components/TLTRaces';
import Wagering from '../../components/base-components/Wagering';
import Employment_Select from '../../components/common/Employment_Select';
import Nationality_Input from '../../components/common/Nationality_Input';
import Province_Input from '../../components/common/Province_Input';
import TaxId_Input from '../../components/common/TaxId_Input';
import Time from '../../components/common/Time';

export const Components: any = {
  socials: SocialNetworks,
  logo: Logo,
  languages: Language,
  time: Time,
  promotions: Promotion,
  authorization: Auth,
  wagering: Wagering,
  deposit: Deposit,
  balance: Balance,
  useSettings: UserSettings,
  headerNavigation: Navigation,
  slider: Slider,
  tltJpWidget: TLTJPWidget,
  tltRaces: TLTRaces,
  blog: BlogWidget,
  search: Search,
  providerFilter: ProviderFilter,
  gameTags: GameTags,
  menu: Mobile_Menu_Button,
  externalLinkButton: ExternalLinkButton,
  sideBarMenuToggle: Logo,
  sideBarNavigation: SideBarNavigation,
  sideBarToggle: SideBarToggle,
  promotionSections: SideBarPromotion,
  favoriteCategories: Logo,
  // if we want add wins component on mock/pages/pages.json add following code
  // "wins":{
  //     "priority": 1,
  //     "styles": {}
  // },
  wins: BL_WINs,
  gameBoxToggle: GameBoxToggle,
  referAFriendToggle: ReferAFriendWidget,
  tagRow: TagRow,
  mailButton: MailButton,
  topCategories: TopCategories,

  //footer
  copyRight: CopyRight,
  bottomNavigation: BottomNavigation,
  operatorBlock: OperatorBlock,
  licence: Licence,
  ageInfo: AgeInfo,
  providerBlock: ProviderBlock,
  backToTop: BackToTop,
  gambleAware: GambleAware,
  termsBriefInfo: TermsBriefInfo,
  mobileApp: MobileApp,
  responsibleGaming: ResponsibleGaming,
  regulationIcons: RegulationIcons,

  // registration
  cpf: CPF_Input,
  username: Username_Input,
  password: Password_Input,
  password2: Repeat_Password_Input,
  email: Email_Input,
  phoneNumber: Phone_Number_Input,
  currency: Currency_Select,
  firstname: FirstName_Input,
  lastname: LastName_Input,
  parentId: ParentId,
  address: Address_Input,
  city: City_Input,
  zipCode: ZipCode_Input,
  province: Province_Input,
  taxId: TaxId_Input,
  nationalId: NationalId_input,
  country: Country_Select,
  employment: Employment_Select,
  birthDate: BirthDate_Input,
  nationality: Nationality_Input,
  gender: Gender_Select,
  liveChatButton: LiveChatButton,
  dni: DNI_Input,
  idMainIssue: ID_Main_Issue_Input,

  //d
  homeWidgets: HomeWidgets,
  featuredGames: FeaturedGames,
  newsLetter: NewsLetter,
  liveChat: LiveChat,
  advertisementList: AdvertisementList,
  awards: Awards,
  certificateList: CerificateList,
  cookieSettingManager: CookieSettingManager,
  downloadApp: MobileApp,
  gameCare: GameCare,
  helpCenter: HelpCenterWidget,
  lionLogo: ImageWidget,
  footerWidgetOne: ImageWidgetLiga,
  footerWidgetTwo: ImageWidgetAwards,
  paymentList: PaymentList,
  providerList: ProviderList,
  homePageGamesSlider: HomePageGamesSlider,
  homePageGamesSliders: HomePageGamesSliders,
  textWidget: TextWidget,
  poker: Poker,
  raffleDraw: RaffleDraw,
  footerLogos: FooterLogos,
};
