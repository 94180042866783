import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertAllTagsArr } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useScrollWithShadow } from 'src/hooks/useScrollWithShadow';
import { setTagsData } from 'src/store/app/actions';
import { setSelectedProvider, setSelectedTag } from 'src/store/games/actions';
import { RootState } from 'src/types/store-types';
import { GAME_TAGS_SCROLL_COUNT } from 'src/utils/constants';
import { useI18n } from '../../../hooks/use-i18n-translation';
import BorderedRow from './BorderedRow';
import SimpleRow from './SimpleRow';
import './styles.scss';

type Props = {
  settings: { priority: number; styles: Styles; type: string };
};
const TagRow: FC<Props> = ({ settings }) => {
  const { type } = settings;
  const { locale } = useI18n();
  const navigate = useNavigate();
  const tagsContainer = useRef<any>();

  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setTagsData = useConnectedAction(setTagsData);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);

  const { selectedCategory, tagsData, sideBarMenuIsOpen } = useSelector((state: RootState) => state.app);
  const { categoriesData, navigation, tagsMetaInfo } = useSelector((state: RootState) => state.configs);
  const { selectedTag } = useSelector((state: RootState) => state.games);
  const { freeSpinGames } = useSelector((state: RootState) => state.integrations);

  const [isScrollBtnVisible, setIsScrollBtnVisible] = useState<boolean>(false);
  const [prevSelectedTag, setPrevSelectedTag] = useState<string>(selectedTag);

  const homeTag = useMemo(() => {
    return navigation.filter((f) => f.name === selectedCategory)[0];
  }, [selectedCategory, navigation]);

  const width = sideBarMenuIsOpen ? window.innerWidth - 260 : window.innerWidth;

  const { onScroll, showStart, showEnd } = useScrollWithShadow(tagsContainer.current, tagsData as any, width);

  const scrollLeft = (): void => {
    tagsContainer.current.scrollLeft -= 100 * GAME_TAGS_SCROLL_COUNT;
  };

  const scrollRight = (): void => {
    tagsContainer.current.scrollLeft += 100 * GAME_TAGS_SCROLL_COUNT;
  };

  const onGameTagChange = (tag: string): void => {
    navigate(`/category/${selectedCategory}`);
    _setSelectedTag(tag);
    if (tag !== prevSelectedTag) {
      setPrevSelectedTag(tag);
      _setSelectedProvider(['all_providers']);
    }
  };

  const scrollToSelectedTag = (): void => {
    if (tagsData) {
      const id =
        tagsData?.allTagNames.length - 1 === tagsData?.allTagNames?.indexOf(selectedTag)
          ? selectedTag
          : tagsData?.allTagNames[tagsData?.allTagNames?.indexOf(selectedTag)];
      const element = document.getElementById(id);
      if (element && !['home'].includes(selectedTag)) {
        element.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
      }
    }
  };

  useEffect(() => {
    if (tagsContainer.current?.scrollWidth > tagsContainer.current?.clientWidth) {
      setIsScrollBtnVisible(true);
    }
  }, [tagsContainer.current?.scrollWidth]);

  useEffect(() => {
    localStorage.getItem('selectedTag') === 'home' && setPrevSelectedTag('allGames');
    if (tagsData?.allTagNames.length) {
      scrollToSelectedTag();
    }
  }, [tagsData?.allTagNames, selectedTag]);

  useEffect(() => {
    if (!!categoriesData && !!selectedCategory && !!homeTag && locale) {
      _setTagsData(convertAllTagsArr(categoriesData, selectedCategory, homeTag, tagsMetaInfo, locale));
    }
  }, [categoriesData, selectedCategory, homeTag, tagsMetaInfo, locale]);

  if (homeTag?.showGamesOfTag) {
    return null;
  }

  if (type === 'simpleRow') {
    return (
      <SimpleRow
        showEnd={showEnd}
        showStart={showStart}
        onScroll={onScroll}
        tagsContainer={tagsContainer}
        freeSpinGames={freeSpinGames}
        selectedCategory={selectedCategory}
        onGameTagChange={onGameTagChange}
        selectedTag={selectedTag}
        gameTags={tagsData}
        scrollLeft={scrollLeft}
        scrollRight={scrollRight}
        isScrollBtnVisible={isScrollBtnVisible}
      />
    );
  }

  return (
    <BorderedRow
      showEnd={showEnd}
      showStart={showStart}
      isScrollBtnVisible={isScrollBtnVisible}
      onScroll={onScroll}
      tagsContainer={tagsContainer}
      scrollLeft={scrollLeft}
      onGameTagChange={onGameTagChange}
      selectedTag={selectedTag}
      scrollRight={scrollRight}
      gameTags={tagsData}
    />
  );
};
export default TagRow;
