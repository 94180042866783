import { FC } from 'react';
import { useSelector } from 'react-redux';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { RootState } from 'src/types/store-types';
import ActivityPopup from './ActivityPopup';
import FinalTourInfo from './FinalTourInfo';
import FirstDepositToBonusWallet from './FirstDepositToBonusWallet';
import FreeSpins from './FreeSpins';
import GameLaunchPreview from './GameLaunchPreview';
import GameUrlFailure from './GameUrlFailure/GameUrlFailure';
import LimitsPopup from './LimitsPopup';
import OpenNewTab from './OpenNewTab';
import RegistrationFailure from './RegistrationFailure';
import ResetPasswordSuccess from './ResetPasswordSuccess';
// import ResumeTournament from './ResumeTournament';
import SpinAndGoStarted from './SpinAndGoStarted';
import SuccessFullyReged from './SuccessFullyReged';
import TournamentResults from './TournamentResults';
import TransactionInfo from './TransactionInfo';

const Dialogs: FC = () => {
  const dialogs = useSelector((state: RootState) => state.dialog.dialogs);
  const parsedPopupIds = JSON.parse(localStorage.getItem('dontShowPopupIds') || '[]') as [];

  const dialogComponents = {
    [EnumDialogsKeys.RESET_PASSWORD_SUCCESS]: () => (
      <ResetPasswordSuccess
        dialogCase={dialogs[EnumDialogsKeys.RESET_PASSWORD_SUCCESS]?.dialogCase}
        email={dialogs[EnumDialogsKeys.RESET_PASSWORD_SUCCESS]?.email}
      />
    ),
    [EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS]: () => (
      <ResetPasswordSuccess
        dialogCase={dialogs[EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS]?.dialogCase}
        email={dialogs[EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS]?.email}
        isChangePassword
      />
    ),
    [EnumDialogsKeys.TOURNAMENT_RESULTS]: () => (
      <TournamentResults data={dialogs[EnumDialogsKeys.TOURNAMENT_RESULTS]?.data} />
    ),
    [EnumDialogsKeys.FINAL_TOUR_INFO]: () =>
      Array.isArray(parsedPopupIds) &&
      parsedPopupIds.length === 0 && <FinalTourInfo data={dialogs[EnumDialogsKeys.FINAL_TOUR_INFO]?.data} />,
    [EnumDialogsKeys.SECCESSFULLY_REGED]: () => <SuccessFullyReged />,
    [EnumDialogsKeys.SPIN_AND_GO_STARTED]: () => (
      <SpinAndGoStarted data={dialogs[EnumDialogsKeys.SPIN_AND_GO_STARTED]?.data} />
    ),
    [EnumDialogsKeys.REGISTRATION_FAILURE]: () => (
      <RegistrationFailure data={dialogs[EnumDialogsKeys.REGISTRATION_FAILURE]?.data} />
    ),
    [EnumDialogsKeys.FREE_SPINS]: () => <FreeSpins data={dialogs[EnumDialogsKeys.FREE_SPINS]?.data} />,
    [EnumDialogsKeys.GAME_LAUNCH_PREVIEW]: () => (
      <GameLaunchPreview data={dialogs[EnumDialogsKeys.GAME_LAUNCH_PREVIEW]?.data} />
    ),
    [EnumDialogsKeys.FIRST_DEPOSIT_TO_BONUS_WALLET]: () => (
      <FirstDepositToBonusWallet data={dialogs[EnumDialogsKeys.FIRST_DEPOSIT_TO_BONUS_WALLET]?.data} />
    ),
    [EnumDialogsKeys.MAIN_TO_POKER_TRANSACTION]: () => (
      <TransactionInfo data={dialogs[EnumDialogsKeys.MAIN_TO_POKER_TRANSACTION]?.data} />
    ),
    [EnumDialogsKeys.GAME_URL_FAILURE]: () => <GameUrlFailure data={dialogs[EnumDialogsKeys.GAME_URL_FAILURE]?.data} />,
    [EnumDialogsKeys.ACTIVITY_POPUP]: () => <ActivityPopup data={dialogs[EnumDialogsKeys.ACTIVITY_POPUP]?.data} />,
    [EnumDialogsKeys.LIMITS_POPUP]: () => <LimitsPopup data={dialogs[EnumDialogsKeys.LIMITS_POPUP]?.data} />,
    [EnumDialogsKeys.DINHU_ANALIZER]: () => <OpenNewTab data={dialogs[EnumDialogsKeys.DINHU_ANALIZER]?.data} />,
  };

  return (
    <>{Object.entries(dialogComponents).map(([key, Component]) => dialogs?.[key as EnumDialogsKeys] && Component())}</>
  );
};

export default Dialogs;
