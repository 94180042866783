import cn from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { IActiveJackpotsTicketsType } from 'src/types/res-dto/raffleDraw';
import './styles.scss';

type IProps = {
  classnames?: string;
  data: IActiveJackpotsTicketsType;
};

const RaffleDrawTickets: FC<IProps> = ({ classnames, data }) => {
  const { t } = useTranslation();

  return (
    <div className={cn('raffle_draw_tickets_container', classnames)}>
      <div className="raffle_draw_tickets_header">
        <div className="player_tickets_row">
          {t('yourTickets')}: <span>{data.tickets.length}</span>
        </div>
        <div className="total_tickets_row">
          {t('totalTickets')}: <span>{data.tickets_count}</span>
        </div>
      </div>
      <div className="raffle_draw_tickets_table_wrapper">
        {data.tickets.length ? (
          <div className="raffle_draw_tickets_table">
            {data.tickets.map((el: number) => (
              <div className="table_ticket_item">
                <span>NO: {el}</span>
                <SvgIcon src={icons.jackpotTicket} color="brand" size="xl" />
              </div>
            ))}
          </div>
        ) : (
          <div className="empty_text">{t('noTickets')}</div>
        )}
      </div>
    </div>
  );
};

export default RaffleDrawTickets;
