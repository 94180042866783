import { FC, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { getFriendsList, getRewards } from 'src/services/referFriend';
import { setReferralLink } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import { RAF_LIMIT, SOCIAL_MEDIA_SHARER } from 'src/utils/constants';
import DesktopRAF from './DesktopRAF';
import MobileRAF from './MobileRAF';
import styles from './styles.module.scss';

interface IReward {
  title: string;
  data: Reward;
}

const ReferAFriend: FC = () => {
  const { t } = useTranslation();
  const personalInfo = useSelector((state: RootState) => state.user.user?.personalInfo);
  const { referralLinks } = useSelector((state: RootState) => state.user);

  const _setReferralLink = useConnectedAction(setReferralLink);

  const [showFriendsList, setShowFriendsList] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState<boolean>(false);
  const [friendsList, setFriendsList] = useState<any>(null);
  const [copiedLink, setCopiedLink] = useState<boolean>(false);
  const [rewards, setRewards] = useState<IReward[]>([
    {
      title: 'rewards_collected',
      data: {
        money: 0,
        freespins: 0,
        tourTicket: 0,
        jackpotTicket: 0,
      },
    },
    {
      title: 'rewards_to_collect',
      data: {
        money: 0,
        freespins: 0,
        tourTicket: 0,
        jackpotTicket: 0,
      },
    },
  ]);

  const link = useMemo(() => {
    return personalInfo?.referralUrl || referralLinks?.referralUrl;
  }, [personalInfo, referralLinks]);

  const getRewardsList = (): void => {
    getRewards().then((res: RewardsRes) => {
      if (res.success) {
        setRewards([
          { title: 'rewards_collected', data: res.result.rewardsCollected },
          { title: 'rewards_to_collect', data: res.result.rewardsToCollect },
        ]);
      }
    });
  };

  const openSocilMedia = (socialMedia: string): void => {
    if (socialMedia !== 'instagram') {
      window?.open(`${SOCIAL_MEDIA_SHARER[socialMedia]}${link}`, '_blank', 'noreferrer');
    }
  };

  const getFriendList = (): void => {
    getFriendsList({ limit: RAF_LIMIT, offset }).then((res) => {
      if (res.success) {
        setFriendsList(res.result);
      }
    });
  };

  const copyLink = (): void => {
    const isiOS = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);
    const clipboardText = link;

    if (isiOS) {
      const input = document?.createElement('input');
      input.setAttribute('value', clipboardText);
      document?.body?.appendChild(input);
      input.select();
      document.execCommand('copy');
      document?.body?.removeChild(input);

      setCopiedLink(true);
      setTimeout(() => {
        setCopiedLink(false);
      }, 3000);
    } else {
      navigator?.clipboard?.writeText(clipboardText).then(() => {
        setCopiedLink(true);
        setTimeout(() => {
          setCopiedLink(false);
        }, 3000);
      });
    }
  };

  useEffect(() => {
    if (link) {
      _setReferralLink({ referralUrl: link, referralId: personalInfo?.referralId || 0 });
      getRewardsList();
      getFriendList();
    }
  }, [link, offset]);

  if (process.env.REACT_APP_WEBSITENAME === 'PapiGames') {
    return <div className={styles.raf_not_available_text}>{t('rafNoLongerAvailableDescription')}</div>;
  }

  if (isMobileOnly) {
    return (
      <MobileRAF
        rewards={rewards}
        friendsList={friendsList}
        setShowFriendsList={setShowFriendsList}
        setShowTermsAndConditions={setShowTermsAndConditions}
        showFriendsList={showFriendsList}
        showTermsAndConditions={showTermsAndConditions}
        openSocilMedia={openSocilMedia}
        link={link}
        copiedLink={copiedLink}
        copyLink={copyLink}
        setOffset={setOffset}
      />
    );
  }

  return (
    <DesktopRAF
      rewards={rewards}
      friendsList={friendsList}
      setShowFriendsList={setShowFriendsList}
      setShowTermsAndConditions={setShowTermsAndConditions}
      showFriendsList={showFriendsList}
      showTermsAndConditions={showTermsAndConditions}
      openSocilMedia={openSocilMedia}
      link={link}
      copiedLink={copiedLink}
      copyLink={copyLink}
      setOffset={setOffset}
    />
  );
};
export default ReferAFriend;
