import cn from 'classnames';
import { FC, useState } from 'react';
import AccordionItem from './AccordionItem';
import './styles.scss';

interface IAccordionDataType {
  title: string;
  description: string;
}

type IProps = {
  title?: string;
  className?: string;
  data: IAccordionDataType[];
};

const Accordion: FC<IProps> = ({ className, title, data }) => {
  // States
  const [openItem, setOpenItem] = useState<null | number>(null);

  // Actions
  const accordionToggleHandler = (id: number): void => {
    openItem === id ? setOpenItem(null) : setOpenItem(id);
  };

  return (
    <div className={cn('accordion_wrapper', className)}>
      {title && <div className="accordion_title">{title}</div>}
      <div className="accordion">
        {data.map((el: IAccordionDataType, idx: number) => (
          <AccordionItem
            key={el.title}
            data={el}
            index={idx}
            isOpen={openItem === idx}
            accordionToggleHandler={accordionToggleHandler}
          />
        ))}
      </div>
    </div>
  );
};

export default Accordion;
