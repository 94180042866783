import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { calculateTimeLeft, isValidDate } from 'src/helpers/utils';
import './styles.scss';

type IProps = {
  classname?: string;
  targetDate: Date | string | null;
  onEndCb?: () => void;
  showDays?: boolean;
  showHours?: boolean;
  showMinutes?: boolean;
  view?: 'dots' | 'names';
  size?: 'xxs' | 'xss' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
  bold?: boolean;
  uppercase?: boolean;
};

const CountdownTimer: FC<IProps> = ({
  classname,
  targetDate,
  onEndCb,
  showDays = true,
  showHours = true,
  showMinutes = true,
  view = 'dots',
  size = 'md',
  bold,
  uppercase,
}) => {
  // States
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  // Effects
  useEffect((): any => {
    if (targetDate && isValidDate(targetDate)) {
      const timer = setInterval(() => {
        const timeLeft = calculateTimeLeft(targetDate);
        if (
          timeLeft.days === '00' &&
          timeLeft.hours === '00' &&
          timeLeft.minutes === '00' &&
          timeLeft.seconds === '00'
        ) {
          clearInterval(timer);
          !!onEndCb && onEndCb();
        }
        setTimeLeft(timeLeft);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [targetDate]);

  return (
    <div
      className={cn(
        'timer',
        {
          [`timer_view__${view}`]: view,
          [`timer_size__${size}`]: size,
          ['timer_text__bold']: bold,
          ['timer_text__uppercase']: uppercase,
        },
        classname
      )}
    >
      {showDays && (
        <span>
          {timeLeft.days}
          {view === 'names' ? 'd ' : null}
        </span>
      )}
      {view === 'dots' && showDays && <span> : </span>}
      {showHours && (
        <span>
          {timeLeft.hours}
          {view === 'names' ? 'h' : null}
        </span>
      )}
      {view === 'dots' && showHours && <span> : </span>}
      {showMinutes && (
        <span>
          {timeLeft.minutes}
          {view === 'names' ? 'm' : null}
        </span>
      )}
      {view === 'dots' && showMinutes && <span> : </span>}
      <span>
        {timeLeft.seconds}
        {view === 'names' ? 's' : null}
      </span>
    </div>
  );
};

export default CountdownTimer;
