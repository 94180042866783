export type appState = {
  sideBarMenuIsOpen: boolean;
  selectedSection: string;
  selectedSubSection: string;
  depositModalIsOpen: boolean;
  selectedCategory: string;
  showUserSetting: boolean;
  homePageBanners: [];
  selectedFooterMenu: string | null;
  customI18n: any;
  showMainBalance: boolean;
  showVipPoints: boolean;
  showBonusWallet: boolean;
  isGameBoxOpen: boolean;
  tournamentIsOpen: boolean;
  deepLink: string;
  generalLoading: boolean[];
  sectionLoading: boolean[];
  cookiesAccepted: string | null;
  tagsData: {
    allTagNames: string[];
    allTagData: {
      providers?: string[];
      tags?: {
        tagTotal: number;
        tagName: string;
        tagTrName?: string;
        tagGames: any[];
      }[];
    };
  } | null;
};

export const APP_SET_MOBILE_LIVECHAT = 'app/APP_SET_MOBILE_LIVECHAT';

export const APP_SET_LOADING = 'app/APP_SET_LOADING';

export const APP_SHOW_USER_SETTINGS = 'app/APP_SHOW_USER_SETTINGS';

export const APP_SET_SETTINGS_REQ = 'app/APP_SET_SETTINGS_REQ';
export const APP_SET_SETTINGS_SUCCESS = 'app/APP_SET_SETTINGS_SUCCESS';
export const APP_SET_SETTINGS_FAIL = 'app/APP_SET_SETTINGS_FAIL';

export const APP_SET_SELECTED_SECTION = 'app/APP_SET_SELECTED_SECTION';

export const APP_SET_OPEN_DEPOSIT_MODAL = 'app/APP_SET_OPEN_DEPOSIT_MODAL';

export const APP_SET_SELECTED_CATEGORY = 'app/APP_SET_SELECTED_CATEGORY';

export const APP_SET_HEIGHT_OF_SIDEBAR = 'app/APP_SET_HEIGHT_OF_SIDEBAR';

export const APP_SET_FREE_SPIND = 'app/APP_SET_FREE_SPIND';

export const SET_EXTERNAL_TOURNAMENT_GAME = 'app/SET_EXTERNAL_TOURNAMENT_GAME';

export const TOGGLE_TOURNAMENT_MODULE_PAGE = 'app/TOGGLE_TOURNAMENT_MODULE_PAGE';

export const APP_SET_FINAL_TOUR_INFO_REQ = 'app/APP_SET_FINAL_TOUR_INFO_REQ';
export const APP_SET_FINAL_TOUR_INFO_SUCCSESS = 'app/APP_SET_FINAL_TOUR_INFO_SUCCSESS';
export const APP_SET_FINAL_TOUR_INFO_FAIL = 'app/APP_SET_FINAL_TOUR_INFOS_FAIL';

export const APP_SET_FOOTER_MENU = 'app/APP_SET_FOOTER_MENU';

export const APP_SET_GAMES_TOTAL_COUNT = 'app/APP_SET_GAMES_TOTAL_COUNT';

export const APP_CUSTOM_I18N = 'app/APP_CUSTOM_I18N';

export const APP_FORCE_CLOSE_GAMEBOX = 'app/APP_FORCE_CLOSE_GAMEBOX';

export const APP_CLOSE_USER_SETTINGS = 'app/APP_CLOSE_USER_SETTINGS';

export const TOGGLE_BALANCE_VISIBILITY = 'app/TOGGLE_BALANCE_VISIBILITY';

export const TOGGLE_SIDEBAR_NAVIGATION_MENU = 'app/TOGGLE_SIDEBAR_NAVIGATION_MENU';
export const OPEN_SIDEBAR_NAVIGATION_MENU = 'app/OPEN_SIDEBAR_NAVIGATION_MENU';

export const APP_SET_GAMEBOX_OPEN = 'app/APP_SET_GAMEBOX_OPEN';

export const APP_SET_GAMEBOX_CLOSE = 'app/APP_SET_GAMEBOX_CLOSE';

export const APP_SET_TOURANAMENT_GAME_OPEN = 'app/APP_SET_TOURANAMENT_GAME_OPEN';

export const APP_SHOW_VIP_POINTS = 'app/APP_SHOW_VIP_POINTS';

export const APP_SHOW_BONUS_WALLET = 'app/APP_SHOW_BONUS_WALLET';

export const APP_SET_ACTUAL_MISSIONS_COUNT = 'app/APP_SET_ACTUAL_MISSIONS_COUNT';

export const APP_CHANGE_NAVIGATION = 'app/APP_CHANGE_NAVIGATION';
export const APP_SET_DEEP_LINK = 'app/APP_SET_DEEP_LINK';

export const APP_SET_SECTION_LOADING = 'user/APP_SET_SECTION_LOADING';

export const APP_SET_GENERAL_LOADING = 'user/APP_SET_GENERAL_LOADING';

export const APP_RESET_SECTION_LOADING = 'user/APP_RESET_SECTION_LOADING';

export const APP_SET_TAGS_DATA = 'user/APP_SET_TAGS_DATA';

export const APP_SET_COOKES = 'app/APP_SET_COOKES';
